var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.statisticalClassifiVisible)?_c('popup',{staticClass:"statistical-classifi-visible",on:{"closeEvent":_vm.closeEvent}},[_c('div',{attrs:{"slot":"popup-name"},slot:"popup-name"},[_vm._v(" "+_vm._s(_vm.$t("statisticsUp.setStatisticClassifiTitle"))+" ")]),_c('div',{attrs:{"slot":"popup-con"},slot:"popup-con"},[_c('div',{staticClass:"popup-container-self"},[_c('div',{staticClass:"list-box"},[_c('div',{staticClass:"title-top"},[_c('div',{staticClass:"left-title-top"},[_vm._v(" "+_vm._s(_vm.$t("statisticsUp.class"))+" ")]),_c('div',{staticClass:"right-title-top"},[_vm._v(" "+_vm._s(_vm.$t("statisticsUp.setTypeDialogTip2"))+" ")])]),_c('div',{staticClass:"scroll-box"},_vm._l((_vm.setStatisticalClassifi.questionTypeConfigs),function(item,index){return _c('div',{key:index,staticClass:"item-statistical-classifi"},[_c('el-input',{attrs:{"placeholder":_vm.$t('statisticsUp.classNamePla')},model:{value:(item.name),callback:function ($$v) {_vm.$set(item, "name", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"item.name"}}),_c('el-cascader',{ref:'changekeyWordRecommend' + index,refInFor:true,attrs:{"collapse-tags":"","options":_vm.intentTree4Radio,"props":{
                                    multiple: true,
                                    value: 'id',
                                    label: 'name',
                                    emitPath: false,
                                    checkStrictly: true,
                                },"show-all-levels":false,"placeholder":_vm.$t('botIntent.searchOrSelectIntent'),"filterable":""},on:{"change":function($event){return _vm.changeIntentGuan($event, item, index)}},model:{value:(item.intentIds),callback:function ($$v) {_vm.$set(item, "intentIds", $$v)},expression:"item.intentIds"}}),_c('i',{directives:[{name:"show",rawName:"v-show",value:(
                                    _vm.setStatisticalClassifi
                                        .questionTypeConfigs.length > 1
                                ),expression:"\n                                    setStatisticalClassifi\n                                        .questionTypeConfigs.length > 1\n                                "}],staticClass:"el-icon-delete cursor",on:{"click":function($event){return _vm.confirmDeleteKeyWord(index)}}})],1)}),0),_c('div',{staticClass:"add-classifi-btn"},[_c('div',{staticClass:"add-btn guoran-tongyichicun-16-13-xinjian iconfont",on:{"click":function($event){return _vm.setStatisticalClassifi.questionTypeConfigs.push(
                                    { name: '', intentIds: [], nodeList: null, ext: null }
                                )}}})])]),_c('div',{staticClass:"statistical-rank"},[_c('div',{staticClass:"statistical-rank-left"},[_vm._v(" "+_vm._s(_vm.$t("statisticsUp.topSystemRank"))+" ")]),_c('div',{staticClass:"statistical-rank-right"},[_c('el-input',{attrs:{"min":"1","max":"100","placeholder":_vm.$t('common.inputPlaceholder'),"type":"number"},on:{"change":_vm.changeFn},model:{value:(_vm.setStatisticalClassifi.topN),callback:function ($$v) {_vm.$set(_vm.setStatisticalClassifi, "topN", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"setStatisticalClassifi.topN"}})],1)])])]),_c('div',{attrs:{"slot":"dialog-footer"},slot:"dialog-footer"},[_c('el-button',{staticClass:"cancel-btn",attrs:{"plain":"","round":""},on:{"click":_vm.closeEvent}},[_vm._v(_vm._s(_vm.$t("common.cancel")))]),_c('el-button',{staticClass:"confirm-btn",staticStyle:{"padding":"0 !important"},attrs:{"type":"primary","round":""},on:{"click":function($event){return _vm.submitForm('')}}},[_vm._v(_vm._s(_vm.$t("common.confirm")))])],1)]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }