var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"gr-right-content-bottom-b"}},[(
            _vm.activeServiceObj.WORKORDER != -1 &&
            _vm.rolePrivilegesList.indexOf('PORTAL_ENTRANCE_WORKORDER') !== -1
        )?_c('div',{staticClass:"gr-left-cbb-c gr-left-cbb-c-w",staticStyle:{"color":"#7367f0","background":"#efedff"},on:{"click":function($event){return _vm.enterModule(_vm.activeServiceObj.WORKORDER)}}},[_vm._m(0),_c('span',{staticClass:"a-text"},[_vm._v(" "+_vm._s(_vm.renderText('', 'WORKORDER'))+" ")])]):_vm._e(),(
            _vm.activeServiceObj.MANAGEMENT != -1 &&
            _vm.rolePrivilegesList.indexOf('PORTAL_ENTRANCE') !== -1
        )?_c('div',{staticClass:"gr-left-cbb-c gr-left-cbb-c-m",staticStyle:{"color":"rgb(255, 196, 54)","background":"rgb(255, 248, 230)"},on:{"click":function($event){return _vm.enterModule(_vm.activeServiceObj.MANAGEMENT)}}},[_vm._m(1),_c('span',{staticClass:"a-text"},[_vm._v(_vm._s(_vm.$t('common.MANAGEMENT')))])]):_vm._e(),(
            _vm.activeServiceObj.IM != -1 &&
            _vm.rolePrivilegesList.indexOf('PORTAL_ENTRANCE_IM') !== -1
        )?_c('div',{staticClass:"gr-left-cbb-c gr-left-cbb-c-i",staticStyle:{"color":"#09ddd5","background":"#e1faf9"},on:{"click":function($event){return _vm.enterModule(_vm.activeServiceObj.IM)}}},[_vm._m(2),_c('span',{staticClass:"a-text"},[_vm._v(" "+_vm._s(_vm.renderText('', 'IM'))+" ")])]):_vm._e(),(_vm.activeServiceObj.KNOWLEDGE != -1 && _vm.rolePrivilegesList.indexOf('PORTAL_ENTRANCE_KNOWLEDGE') !== -1
                )?_c('div',{staticClass:"gr-left-cbb-c gr-left-cbb-c-know",staticStyle:{"color":"#555555","background":"#F8F8F8"},on:{"click":function($event){return _vm.enterModule(_vm.activeServiceObj.KNOWLEDGE)}}},[_vm._m(3),_c('span',{staticClass:"a-text"},[_vm._v(" "+_vm._s(_vm.renderText('', 'KNOWLEDGE'))+" ")])]):_vm._e(),(_vm.activeServiceObj.ASKAI != -1)?_c('div',{staticClass:"gr-left-cbb-c gr-left-cbb-c-askai",staticStyle:{"color":"#366aff","background":"#eff7ff"},on:{"click":function($event){return _vm.enterModule(_vm.activeServiceObj.ASKAI, 'ASKAI')}}},[_vm._m(4),_c('span',{staticClass:"a-text"},[_vm._v(" "+_vm._s(_vm.renderText('', 'ASKAI'))+" ")])]):_vm._e(),(_vm.activeServiceObj.QUESTION != -1)?_c('div',{staticClass:"gr-left-cbb-c gr-left-cbb-c-open",staticStyle:{"color":"#366aff","background":"#eff3ff"},on:{"click":function($event){return _vm.enterModule(_vm.activeServiceObj.QUESTION, 'QUESTION')}}},[_vm._m(5),_c('span',{staticClass:"a-text"},[_vm._v(" "+_vm._s(_vm.renderText('', 'QUESTION'))+" ")])]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"icon"},[_c('i',{staticClass:"iconfont guoran-askservice-logo03"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"icon"},[_c('i',{staticClass:"iconfont guoran-tongyichicun-guanlixitong1"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"icon"},[_c('i',{staticClass:"iconfont guoran-guanlixitong1"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"icon"},[_c('img',{attrs:{"src":require("../../../assets/images/knowledge.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"icon"},[_c('i',{staticClass:"iconfont guoran-AskAI-logo-dinggao"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"icon"},[_c('img',{attrs:{"src":require("../../../assets/images/AskQNR.png")}})])
}]

export { render, staticRenderFns }