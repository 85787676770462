<template>
    <div :id="['gr-test',]" v-if="testDrawerVisible">
        <div
            v-if="showPreview"
            style="
                width: 100vw;
                height: 100vh;
                z-index: 10000;
                position: fixed;
                top: 0;
                left: 0;
                background-color: rgba(0, 0, 0, 0.8);
            "
        >
            <div
                @click="showPreview = false"
                style="
                    color: white;
                    font-size: 30px;
                    padding: 30px 30px 15vh;
                    text-align: right;
                    cursor: pointer;
                "
            >
                <span class="el-icon-close"></span>
            </div>
            <img
                style="max-height: 70vh; max-width: 70vw"
                :src="previewImageUrl"
                alt
                srcset
            />
        </div>
        <div class="test-box">
            <div class="gr-test-header">
                <div class="gth-bot-info">
                    <div v-if="isShow" class="gth-bot-loading">
                        <img
                            src="./../../assets/images/loading_test_2.png"
                            alt=""
                            srcset=""
                        />
                    </div>
                    <div
                        ref="intentRefName"
                        @click="emptyClick()"
                        class="gth-bot-info-avt"
                    >
                        <img
                            :src="currentBot.botProfilePhoto"
                            alt=""
                            srcset=""
                        />
                    </div>
                    <span v-if="isShow" class="gth-bot-info-name">
                        正在重新训练机器人，请稍后~
                    </span>
                    <span
                        v-if="!isShow && currentBot != null"
                        class="gth-bot-info-name"
                    >
                        <span class="gth-bot-info-name-text">
                            {{ currentBot.name }}
                        </span>
                        <el-popover
                            popper-class="popover-box"
                            v-if="botList.length > 1"
                            placement="bottom"
                            trigger="hover"
                            width="250"
                        >
                            <div class="gth-bot-list">
                                <el-radio-group
                                    v-model="currentBot.id"
                                    @change="changeCurrentBot"
                                >
                                    <el-radio
                                        v-for="bot in botList"
                                        :key="bot.id"
                                        :label="bot.id"
                                        >{{ bot.name }}</el-radio
                                    >
                                </el-radio-group>
                            </div>

                            <span class="gth-bot-change" slot="reference">
                                <i
                                    class="iconfont guoran-tongyichicun-paixu"
                                ></i>
                            </span>
                        </el-popover>
                    </span>
                </div>
                <div class="gth-bot-test-handle">
                    <span
                        v-if="!isShow"
                        class="el-icon-refresh"
                        @click="cleanSession(true)"
                    ></span>
                    <span
                        class="el-icon-close"
                        @click="handleClose(false)"
                    ></span>
                </div>
            </div>
            <div id="grContent" class="gr-content" ref="grContent">
                <ul class="chat-list">
                    <li
                        v-for="(item, index) in chatList"
                        :key="index"
                        :class="[
                            'chat-cell',
                            item.user === 'bot'
                                ? 'via-bot-outer'
                                : 'via-user-outer',
                            item.type === 'answer_intellect_guide'
                                ? 'via-intellect_guide'
                                : '',
                        ]"
                    >
                        <div class="grt-user-avt-box">
                            <img
                                v-if="
                                    item.user === 'bot' &&
                                    item.type !== 'action_feedback' &&
                                    item.type !== 'answer_intellect_guide'
                                "
                                :src="currentBot.botProfilePhoto"
                                alt=""
                                srcset=""
                                class="grt-user-avt"
                            />
                            <!-- <span
                                v-if="
                                    item.user === 'bot' &&
                                    item.type !== 'action_feedback'
                                "
                                class="
                                    grt-user-avt
                                    iconfont
                                    guoran-askbotdandutubiao2
                                "
                            ></span> -->
                        </div>

                        <img
                            class="thr-w"
                            v-if="
                                item.user === 'bot' &&
                                item.type !== 'action_feedback' &&
                                item.type !== 'answer_intellect_guide' &&
                                (item.type !== 'user_image' ||
                                    item.type !== 'IMAGE') &&
                                !(
                                    item.type == 'answer_image' &&
                                    !item.showRecommend
                                ) &&
                                !(
                                    item.type == 'answer_video' &&
                                    !item.showRecommend
                                )
                            "
                            src="./../../assets/images/wthr.png"
                            alt=""
                            srcset=""
                        />
                        <div
                            :class="[
                                'chat-content',
                                item.type === 'action_feedback'
                                    ? 'action_feedback'
                                    : 'false',
                                item.type !== 'action_feedback' &&
                                (item.type !== 'user_image' ||
                                    item.type !== 'IMAGE') &&
                                !(
                                    item.type == 'answer_image' &&
                                    !item.showRecommend
                                ) &&
                                !(
                                    item.type == 'answer_video' &&
                                    !item.showRecommend
                                )
                                    ? 'chat-content-has-bg'
                                    : 'chat-content-no-bg',
                            ]"
                        >
                            <div
                                :class="['chat-content-user-new',item.isLoading ? 'loading' : '']"
                                v-if="(item.type === 'TEXT' || item.type === 'IMAGE') && item.user === 'user'"
                            >
                                <div v-if="item.isLoading" class="message-loading">
                                    <div class="user-detail">
                                        <div class="media-body r-loading-box">
                                            <msg-loading></msg-loading>
                                        </div>
                                    </div>
                                </div>
                                <template v-else>
                                    <span v-if="item.type === 'TEXT'">{{ item.content }}</span>
                                    <div class="user-send-image" v-else @click="viewImage(item)">
                                        <el-image
                                            :src="item.content.urls[0]"
                                            :fit="'contain'">
                                        </el-image>
                                    </div>
                                    <div
                                        v-show="
                                            item.requestCompleted &&
                                            item.needShowIntent
                                        "
                                        class="gr-test-intent-point"
                                    >
                                        <el-popover
                                            v-if="item.isShowSelect"
                                            ref="popover"
                                            popper-class="gr-test-intent-point-pop"
                                            width="250"
                                            :id="'pop_' + index + '_pop'"
                                            trigger="click"
                                            style="padding: 0"
                                            :visible-arrow="false"
                                            @show="showIntentSelect"
                                            @hide="hideIntentSelect"
                                        >
                                            <div
                                                class="gtip-intent-tree-outer"
                                                style="
                                                    text-align: right;
                                                    margin: 0;
                                                    padding: 0;
                                                    border: none;
                                                "
                                            >
                                                <el-input
                                                    ref="searchIntent"
                                                    autocomplete="off"
                                                    new-password="true"
                                                    size="small"
                                                    prefix-icon="el-icon-search"
                                                    placeholder="请输入意图名称"
                                                    @focus="searchAllSkillFocus"
                                                    @keyup.enter.native="
                                                        searchAllSkillIntent
                                                    "
                                                    @input="searchAllSkillIntent"
                                                    v-model.trim="searchIntentName"
                                                ></el-input>
                                                <ul class="test-intent-search-list">
                                                    <li
                                                        class="
                                                            test-intent-search-list-cell
                                                        "
                                                        @click="
                                                            switchSkillClass(
                                                                intentCell,
                                                                item.content,
                                                                index
                                                            )
                                                        "
                                                        v-for="intentCell in searchAllSkillIntentList"
                                                        :key="intentCell.id"
                                                    >
                                                        {{ intentCell.name }}
                                                    </li>
                                                </ul>
                                                <el-tree
                                                    v-show="
                                                        searchAllSkillIntentList.length ===
                                                        0
                                                    "
                                                    :data="intentionList"
                                                    :props="optionProps"
                                                    node-key="id"
                                                    ref="intentSelectTree"
                                                    accordion
                                                    @node-click="
                                                        (data, node, cell) =>
                                                            changeIntentNew(
                                                                data,
                                                                node,
                                                                cell,
                                                                item,
                                                                index
                                                            )
                                                    "
                                                >
                                                    <span
                                                        class="
                                                            custom-tree-node
                                                            gtip-intent-tree-item
                                                        "
                                                        slot-scope="{ node, data }"
                                                    >
                                                        <span>{{
                                                            node.label
                                                        }}</span>
                                                    </span></el-tree
                                                >
                                            </div>
                                            <div
                                                v-if="!item.intentId"
                                                slot="reference"
                                                class="gtip-select-name"
                                                :style="{
                                                    width: !item.isShowDiscriminate
                                                        ? '230px'
                                                        : '260px',
                                                }"
                                            >
                                                <span
                                                    class="gtip-select-name-text"
                                                    :style="{
                                                        width: !item.isShowDiscriminate
                                                            ? '230px'
                                                            : '260px',
                                                    }"
                                                >
                                                    未识别到意图，可选择意图，提高识别率</span
                                                >
                                                <span
                                                    v-if="showArrow"
                                                    class="
                                                        arrow-icon
                                                        el-icon-arrow-down
                                                    "
                                                ></span>
                                                <span
                                                    v-if="!showArrow"
                                                    class="
                                                        arrow-icon
                                                        el-icon-arrow-up
                                                    "
                                                ></span>
                                            </div>
                                            <div
                                                v-else
                                                slot="reference"
                                                class="gtip-select-name"
                                                :style="{
                                                    width: !item.isShowDiscriminate
                                                        ? '230px'
                                                        : '260px',
                                                }"
                                            >
                                                <span
                                                    class="gtip-select-name-text"
                                                    :style="{
                                                        width: !item.isShowDiscriminate
                                                            ? '230px'
                                                            : '260px',
                                                    }"
                                                    >{{
                                                        getIntentAllLocal(
                                                            item.intentId
                                                        )
                                                    }}</span
                                                >
                                                <span
                                                    v-if="showArrow"
                                                    class="
                                                        arrow-icon
                                                        el-icon-arrow-down
                                                    "
                                                ></span>
                                                <span
                                                    v-if="!showArrow"
                                                    class="
                                                        arrow-icon
                                                        el-icon-arrow-up
                                                    "
                                                ></span>
                                            </div>
                                        </el-popover>
                                        <span
                                            class="intent-serch-icon-box"
                                            v-show="!item.isShowDiscriminate"
                                            @click="clickSearch(item)"
                                        >
                                            <svg
                                                t="1655173732117"
                                                class="icon"
                                                viewBox="0 0 1024 1024"
                                                version="1.1"
                                                xmlns="http://www.w3.org/2000/svg"
                                                p-id="40719"
                                                width="16"
                                                height="16"
                                            >
                                                <path
                                                    d="M733.564 1024H0V0h1024v733.687538h-61.397997V61.27446H61.27446v901.327543h672.28954v61.397997z"
                                                    p-id="40720"
                                                    fill="#ffffff"
                                                ></path>
                                                <path
                                                    d="M509.467487 174.681626c-184.935215 0-334.785861 149.850645-334.785861 334.785861s149.850645 334.785861 334.785861 334.785861a333.674026 333.674026 0 0 0 221.872844-84.005308l237.191458 237.191458 34.961033-34.961033-237.685608-237.685607a333.426951 333.426951 0 0 0 78.446134-215.325371c0-184.935215-149.850645-334.785861-334.785861-334.785861z m0 620.156835a285.000362 285.000362 0 1 1 263.010737-174.311015 285.865122 285.865122 0 0 1-151.950778 151.950778 284.012064 284.012064 0 0 1-111.059959 22.360237z"
                                                    p-id="40721"
                                                    fill="#ffffff"
                                                ></path>
                                                <path
                                                    d="M341.580408 509.467487h-49.414887A217.549041 217.549041 0 0 1 583.095669 304.889854l-16.801062 46.573531a167.887079 167.887079 0 0 0-224.714199 158.004102z"
                                                    p-id="40722"
                                                    fill="#ffffff"
                                                ></path>
                                            </svg>
                                        </span>
                                    </div>
                                    <div :class="['intnet-heathly',item.requestCompleted && item.needShowIntent ? 'is' : 'no']" v-if="!item.isShowDiscriminate">
                                        <template v-if="item.testWindowDto.intentId && item.testWindowDto.skillName != '特殊规则'">
                                            <div v-if="!item.testWindowDto.onLine && item.testWindowDto.onLine !== null && !CompanyIsOld"> <div class="icon">!</div>该意图未达标已下线，只能在测试窗中测试</div>
                                            <div v-if="!item.testWindowDto.upToStandard && item.testWindowDto.upToStandard !== null && CompanyIsOld"> <div class="icon">!</div>该意图未达标</div>
                                        </template>
                                    </div>
                                    <transition name="expand">
                                    <div
                                        v-show="item.isShowDiscriminate"
                                        class="discriminate-info"
                                    >
                                        <ul class="discriminate-info-ul">
                                            <li class="diu-cell intent-cell">
                                                <span class="diu-cell-name">意图</span >
                                                <div class="intent-cell-popover">
                                                    <span :class="['diu-cell-value one-column-ellipsis',item.testWindowDto.skillName != '特殊规则' ? 'cursor' : '']"  @click="goIntentLocation(item)">{{item.testWindowDto.intentName}}</span>
                                                     <el-popover
                                                        ref="popovers"
                                                        popper-class="gr-test-intent-point-pop"
                                                        :id="'pop_' + index + '_pops'"
                                                        trigger="click"
                                                        style="padding: 0"
                                                        width="270"
                                                        :append-to-body="false"
                                                        :visible-arrow="false"
                                                        @show="showIntentSelect"
                                                        @hide="hideIntentSelect"
                                                    >
                                                     <div
                                                        class="gtip-intent-tree-outers"
                                                        style="text-align: right;margin: 0;padding: 0; border: none;" >
                                                        <div class="box">
                                                             <el-input
                                                                ref="searchIntent"
                                                                autocomplete="off"
                                                                new-password="true"
                                                                size="small"
                                                                prefix-icon="el-icon-search"
                                                                placeholder="请输入意图名称"
                                                                @focus="searchAllSkillFocus"
                                                                @keyup.enter.native="
                                                                    searchAllSkillIntent
                                                                "
                                                                @input="searchAllSkillIntent"
                                                                v-model.trim="searchIntentName"
                                                            ></el-input>
                                                            <ul class="test-intent-search-list"  v-if="searchAllSkillIntentList && searchAllSkillIntentList.length > 0">
                                                                <li
                                                                    class="
                                                                        test-intent-search-list-cell
                                                                    "
                                                                    @click="
                                                                        switchSkillClass(
                                                                            intentCell,
                                                                            item.content,
                                                                            index
                                                                        )
                                                                    "
                                                                    v-for="intentCell in searchAllSkillIntentList"
                                                                    :key="intentCell.id"
                                                                >
                                                                    {{ intentCell.name }}
                                                                </li>
                                                            </ul>
                                                            <el-tree
                                                                v-show="
                                                                    searchAllSkillIntentList.length ===
                                                                    0
                                                                "
                                                                :data="intentionList"
                                                                :props="optionProps"
                                                                node-key="id"
                                                                ref="intentSelectTree"
                                                                accordion
                                                                @node-click="
                                                                    (data, node, cell) =>
                                                                        changeIntentNew(
                                                                            data,
                                                                            node,
                                                                            cell,
                                                                            item,
                                                                            index
                                                                        )
                                                                "
                                                            >
                                                                <span
                                                                    class="
                                                                        custom-tree-node
                                                                        gtip-intent-tree-item
                                                                    "
                                                                    slot-scope="{ node, data }"
                                                                >
                                                                    <span>{{
                                                                        node.label
                                                                    }}</span>
                                                                </span></el-tree>
                                                        </div>
                                                        
                                                    </div>
                                                    <div slot="reference" class="mark-box">
                                                        <i class="iconfont guoran-tongyichicun-biaozhu"></i>
                                                    </div>
                                                    </el-popover>
                                                </div>
                                            </li>
                                            <div class="diu-cell" v-if="item.testWindowDto.intentId && item.testWindowDto.skillName != '特殊规则'">
                                                <div class="is-up-to-standard" v-if="!item.testWindowDto.onLine && item.testWindowDto.onLine !== null && !CompanyIsOld"><div class="icon">!</div>该意图未达标已下线，只能在测试窗中测试</div>
                                                <div class="is-up-to-standard" v-if="!item.testWindowDto.upToStandard && item.testWindowDto.upToStandard !== null && CompanyIsOld"><div class="icon">!</div>该意图未达标</div>
                                            </div>
                                            <li class="diu-cell">
                                                <span class="diu-cell-name"
                                                    >知识库</span
                                                >
                                                <span class="diu-cell-value">{{
                                                    item.testWindowDto.skillName
                                                }}</span>
                                            </li>
                                            <li class="diu-cell">
                                                <span class="diu-cell-name"
                                                    >分类</span
                                                >
                                                <span class="diu-cell-value">{{
                                                    item.testWindowDto
                                                        .intentTypeName
                                                }}</span>
                                            </li>
                                            <li class="diu-cell">
                                                <span class="diu-cell-name"
                                                    >识别引擎</span
                                                >
                                                <span class="diu-cell-value">{{
                                                    item.testWindowDto
                                                        .recognizeEngines
                                                }}</span>
                                            </li>
                                            <li class="diu-cell entity-box" v-if="item.testWindowDto.entity && item.testWindowDto.entity.length > 0">
                                                <span class="diu-cell-name">实体</span>
                                                <span class="diu-cell-value">实体值</span>
                                            </li>
                                            <div v-if="item.testWindowDto.entity && item.testWindowDto.entity.length > 0">
                                                <li  v-for="(diuCell, diucellIndex) in item.testWindowDto.entity" :key="`diu${diucellIndex}${diuCell.entityName}`" class="diu-cell ">
                                                    <span class="diu-cell-name">{{diuCell.entityName}}</span>
                                                    <span class="diu-cell-value">{{diuCell.entityValue}}</span>
                                                </li>
                                            </div>
                                        </ul>
                                        <span
                                            @click="hideDiscriminate(item)"
                                            class="close-discriminate-info"
                                        >
                                            <i class="el-icon-arrow-up"></i>
                                        </span>
                                    </div>
                                    </transition> 
                                 </template> 
                            </div>
                            <div 
                                class="answer-text-content"
                                v-html="item.text"
                                v-if="item.type === 'answer_text' && item.user === 'bot'">
                            </div>
                            <div
                                :class="[item.version == 1?'new-version-rich':'']"
                                v-html="item.text"
                                v-if="
                                    item.type === 'answer_rich_text_pro' &&
                                    item.user === 'bot'
                                "
                            ></div>

                            <ActionAlert
                                :actionAlertIframe="item.content"
                                v-if="item.type === 'action_alert_iframe'"
                            ></ActionAlert>

                            <TicketMessage
                                @btnClick="templateInfo"
                                :content="item.content"
                                v-if="item.type === 'answer_card_template'"
                            >
                            </TicketMessage>

                            <TextMessage
                                @submitClick="confirmValue"
                                :text="item.content"
                                v-if="item.type === 'action_confirm_value'"
                            >
                            </TextMessage>
                            <FormTemplate
                                @getTemplateList="
                                    (param) => getTemplateList(param, item.id)
                                "
                                :ref="'formTemplate' + item.id"
                                @submitClick="formCommit"
                                :formList="item.content"
                                v-if="item.type === 'action_form'"
                                :uploadUrl="uploadUrl"
                                :mainId="mainId"
                            >
                            </FormTemplate>
                            <FeedBack
                                v-if="item.type === 'action_feedback'"
                                :feedBack="item.content"
                                @positiveFeedback="positiveFeedback"
                            ></FeedBack>
                            <!-- <span v-if="item.type === 'answer_intellect_guide'">{{item}}</span> -->
                            <AiGuide
                                v-if="item.type === 'answer_intellect_guide'"
                                :aiGuide="item"
                                @sendAiGuide="sendAiGuide"
                            ></AiGuide>
                            <AnswerVoice
                                v-if="item.type === 'answer_voice'"
                                @stopOtherAudio="stopOtherAudio"
                                ref="audioTagCell"
                                :msg="item"
                            ></AnswerVoice>

                            <!--              //FormTemplate-->

                            <img
                                v-if="item.type === 'answer_image'"
                                width="150px"
                                :src="item.url"
                                alt
                                @click="previewImage(item.url)"
                            />
                            <!-- <video-player
                                v-if="item.type === 'answer_video'"
                                class="video-player vjs-custom-skin"
                                :options="videoOptions(item.content)"
                                style="width: 300px;"
                            /> -->
                            <div
                                style="border-radius: 25px"
                                v-if="item.type === 'answer_video'"
                            >
                                <video
                                    style="
                                        margin-bottom: -10px;
                                        border-radius: 25px;
                                    "
                                    width="280"
                                    height="auto"
                                    :src="item.content.url"
                                    :controlslist="nodownload && 'nodownload'"
                                    controls
                                >
                                    您的浏览器不支持 HTML5 video 标签。
                                </video>
                            </div>

                            <div
                                v-if="item.type === 'answer_radio'"
                                class="link-list-outer"
                            >
                                <!-- <span>{{ item.description }}</span>
                                <span
                                    v-for="(cell, index) in item.options"
                                    :key="item.nodeId + '_' + index"
                                    class="link-cell cursor"
                                    @click="
                                        sendTestContent(
                                            'RADIO',
                                            item.actionId +
                                                '_' +
                                                index +
                                                '_' +
                                                cell.value,
                                            cell.name,
                                            false
                                        )
                                    "
                                    >{{ cell.name }}</span
                                > -->
                                <answer-radio
                                    :msg="item"
                                    @onRadioClick="onRadioClick"
                                ></answer-radio>
                            </div>
                            <div
                                v-if="
                                    item.type === 'action_send_message_to_bot'
                                "
                                class="link-list-outer"
                            >
                                <span>{{ item.description }}</span>
                                <span
                                    v-for="(cell, index) in item.options"
                                    :key="'_' + index"
                                    class="link-cell cursor"
                                    @click="
                                        sendTestContent(
                                            'action_send_message_to_bot',
                                            cell.content,
                                            cell.name,
                                            false,
                                            item.nodeId
                                        )
                                    "
                                    >{{ cell.name }}</span
                                >
                            </div>
                            <div
                                v-if="item.type === 'unSatisfactionChoose'"
                                class="link-list-outer"
                            >
                                <span>{{ item.content.description }}</span>
                                <span
                                    v-for="(cell, index) in item.content
                                        .options"
                                    :key="index"
                                    class="link-cell cursor"
                                    @click="
                                        chatList.push({
                                            type: 'TEXT',
                                            content: cell.name,
                                            user: 'user',
                                        }),
                                            addAnswerList([
                                                {
                                                    type: 'answer_text',
                                                    content: {
                                                        list: [cell.answer],
                                                    },
                                                },
                                            ])
                                    "
                                    >{{ cell.name }}</span
                                >
                            </div>
                            <div
                                v-if="item.type === 'action_satisfaction'"
                                class="link-list-outer"
                            >
                                <span>{{ item.content.satisfactionDes }}</span>
                                <span
                                    class="link-cell cursor"
                                    @click="
                                        satisfacte(
                                            '满意',
                                            item.content.satisfactoryAnswer,
                                            null
                                        )
                                    "
                                    >满意</span
                                >
                                <span
                                    class="link-cell cursor"
                                    @click="
                                        satisfacte(
                                            '不满意',
                                            item.content.unsatisfiedAnswer,
                                            item.content.answerRadio
                                        )
                                    "
                                    >不满意</span
                                >
                            </div>
                            <div
                                v-if="
                                    (item.type === 'answer_text' ||
                                        item.type === 'answer_rich_text_pro' ||
                                        item.type == 'answer_image' ||
                                        item.type == 'answer_video') &&
                                    item.showRecommend
                                "
                                class="link-list-outer-recommend"
                            >
                                <span v-if="item.recommend.description != ''" class="llor-desc one-column-ellipsis">{{
                                    item.recommend.description
                                }}</span>
                                <span
                                    v-for="(cell, index) in item.recommend.list"
                                    style="padding: 4px"
                                    :key="cell.id + '_' + index"
                                    class="link-cell cursor"
                                    @click="
                                        sendTestContent(
                                            'RADIO',
                                            'recdNodeId' +
                                                '_' +
                                                index +
                                                '_' +
                                                cell.id,
                                            cell.text,
                                            false
                                        )
                                    "
                                >
                                    <span v-if="cell.text == ''" class="llor-link-text">未配置关联意图推荐文案</span>
                                    <span v-else class="llor-link-text">{{
                                        cell.text
                                    }}</span>
                                    <span class="el-icon-arrow-right"></span
                                ></span>
                            </div>
                        </div>
                        <img
                            class="thr-b"
                            v-if="item.user !== 'bot'"
                            src="./../../assets/images/bthr.png"
                            alt=""
                            srcset=""
                        />
                    </li>
                </ul>
            </div>
            <div
                :class="[
                    'gr-test-footer',
                    fastSelectList.length == 0 ? 'mini-h' : 'max-h',
                ]"
            >
                <div v-if="fastSelectList.length != 0" class="fast-select-test">
                    <span
                        v-for="(item, fastselectIndex) in fastSelectList"
                        :key="fastselectIndex"
                        @click="
                            sendTestContent(
                                'FAST_SELECT',
                                JSON.stringify(item),
                                item.text,
                                true
                            )
                        "
                        class="gr-fast-select-cell"
                        >{{ item.text }}</span
                    >
                </div>
                <div class="iput-box">
                    <div class="send-message">
                        <el-input
                            ref="input"
                            size="small"
                            placeholder="请输入测试问题"
                            @focus="focusEvent"
                            @input="inputEvent"
                            autofocus
                            v-model="testValue"
                            @keyup.enter.native="sendTestContent('TEXT', testValue, testValue, true)"
                            @paste.native="pasteFn">
                        </el-input>
                        <div class="send-message-btn" v-if="canSendMessage"  @click="sendTestContent('TEXT', testValue, testValue, true)">
                            <i class="iconfont guoran-tongyichicun-fasong"></i>
                        </div>
                        <div class="send-message-btn" v-else  @click="showImageContent">
                            <i  class="arsenal_icon arsenalplus-solid"></i>
                        </div>
                    </div>
                    <div class="upload-image" v-if="showUploadImg">
                        <el-upload
                            name="file"
                            action="/open/media/file/upload"
                            accept=".jpg, .JPG, .jpeg, .JPEG, .png, .PNG"
                            :before-upload="beforeUpload"
                            :http-request="aliyunOssRequest"
                            list-type="picture-card"
                            :show-file-list="false">
                            <i class="iconfont guoran-write-25"></i>
                        </el-upload>
                        <!-- <div class="upload-image-btn">
                            <i class="iconfont guoran-write-25"></i>
                        </div> -->
                    </div>
                </div>
               
            </div>
        </div>
        <div v-if="iframeVisible" class="closeIframe">
            <span @click="closeIframe">X</span>
        </div>
        <iframe
            frameborder="no"
            v-if="iframeVisible"
            id="template-iframe"
            ref="iframe"
            :src="src"
        ></iframe>
        <imageView
            v-if="showImageViewer"
            :previewList.sync="previewList"
            :showViewer.sync="showImageViewer"
            :currentImage="currentImage"
            :isCanDel="false">
        </imageView>
        <popup 
            v-if="dialogVisible" 
            @closeEvent="dialogVisible = false" 
            class="paste-imgae-popup" 
            :haveTips="false"
        >
            <div slot="popup-con" >
               <div v-loading="media_inLoading" style="max-height:300px;min-height:100px;">
                    <el-image 
                        style="max-height:300px"
                        :mode="'contain'" 
                        :src="pasteImgaeUrl">
                    </el-image>
               </div>
            </div>
            <div slot="dialog-footer">
               <div slot="dialog-footer">
                    <el-button @click="cancelSend" plain  round class="cancel-btn" >取消</el-button>
                    <el-button type="primary" @click="confirmSend" round  class="confirm-btn">确定</el-button>
                </div>
            </div>
        </popup>
    </div>
</template>
<script>
import "video.js/dist/video-js.css";
import $ from "jquery";
// import { videoPlayer } from "vue-video-player";
import { randomNum } from "../../utils/math_utils";
import TicketMessage from "askbot-dragon/src/components/message/TicketMessage";
import TextMessage from "askbot-dragon/src/components/message/TextMessage";
import FormTemplate from "askbot-dragon/src/components/formTemplate";
import FeedBack from "askbot-dragon/src/components/feedBack";
import AiGuide from "askbot-dragon/src/components/AiGuide";
import AnswerVoice from "askbot-dragon/src/components/AnswerVoice";
import ActionAlert from "askbot-dragon/src/components/ActionAlertIframe";
import AnswerRadio from "askbot-dragon/src/components/answerRadio";
import { v4 as uuidv4 } from "uuid";
import { ossConfig, multipartUpload, ossFileUrl } from "../ChatFlow/component/answer/js/AliyunIssUtilWithProcess";
import imageView from '../../components/imageView.vue';
import Popup from "../../components/popup.vue";
import MsgLoading from "askbot-dragon/src/components/msgLoading";
window.addEventListener(
    "message",
    function (messageEvent) {
        var data = messageEvent.data;
        let iframeObj = document.getElementById(data.iframeId);
        if (iframeObj) {
            if (data.width) {
                iframeObj.width = data.width + "px";
            }
            if (data.height) {
                iframeObj.height = data.height + "px";
            }
        }
    },
    false
);
export default {
    props: ["testDrawerVisibleP"],
    components: {
        TicketMessage,
        TextMessage,
        FormTemplate,
        FeedBack,
        ActionAlert,
        AnswerRadio,
        AiGuide,
        AnswerVoice,
        imageView,
        Popup,
        MsgLoading
    },
    data() {
        return {
            divCellList: [
                {
                    name: "知识库",
                    value: "打印机问题",
                },
                {
                    name: "分类",
                    value: "打印机问题开关",
                },
                {
                    name: "识别引擎",
                    value: "规则引擎",
                },
                {
                    name: "实体",
                    value: "12",
                },
                {
                    name: "时间",
                    value: "2011-05-25 16:02:08",
                },
            ],
            showArrow: true,
            // 搜索意图
            searchIntentName: "",
            searchAllSkillIntentList: [], // 搜索列表
            showSearchAllSkillIntent: false, // 搜索列表显隐
            showSearchAllSkillIntentNoData: false, // 无数据显隐
            isSearch: false,

            src: "",
            iframeWin: {},
            iframeVisible: false,
            previewImageUrl: "",
            showPreview: false,
            botList: [],
            currentBot: {
                id: null,
                name: null,
                apiKey: "",
                userId: 0,
                botProfilePhoto: "",
            }, // 当前机器人
            intentionList: [],

            optionProps: {
                value: "id",
                label: "name",
                children: "children",
            },
            direction: "rtl",
            testDrawerVisible: false, //  测试抽屉
            testValue: "",
            sendingMsg: true,
            sessionId: "0",
            chatList: [],
            isShow: false,
            uploadUrl: "/api/oss/public",
            fastSelectList: [],
            CompanyIsOld:false,
            canSendMessage:false, // 是否可以发送消息
            showUploadImg:false, // 是否显示发送图片
            showImageViewer:false,
            previewList:[],
            currentImage:{},
            pasteImgaeUrl:'',
            dialogVisible:false,
            media_inLoading:false,
            isPaste:false,
            mainId:'',
            nodownload: false,
        };
    },
    mounted () {
        window.previewImage = this.previewImage;
        window.__rich_text_pro_button_onclick = this.askCkeditorBtnClick;
        this.cleanSession();
        this.mainId = localStorage.getItem('_mainId')

        // 滔搏视频禁止下载
        if(this.mainId == '56bec4b273ca4534b8c6d05479908e81' || this.mainId == '46b76aebe572421fa10e51f8f2086056' || this.mainId == '0b73fec788b14f8c8e7d87c2eb5e1984'){
            this.nodownload = true;
        }

        // this.iframeWin = this.$refs.iframe.contentWindow;1
    },
    methods: {
         /**
         * 图片压缩方法
         * @method imgCompress
         * @param {imgFile:图片原文件} file
         * @param {imgType:压缩后的图片的格式，可选image/webp或image/jpeg，其他格式无效} string
         * @param {quality：压缩比例，0~1，仅当imgType为有效值时生效} number
         * @return 调用父组件方法 保存模板管理 模板
         */
        imgCompress(imgFile,imgType,quality) {
            if(!imgType){
                imgType = 'image/jpeg';//默认输出webp格式的图片
            }
            let _that = this;
            return new Promise((resolve,reject)=>{
                let src=URL.createObjectURL(imgFile);
                let img=new Image();//创建一个img标签
                img.src=src;
                img.onload=function () {
                    try {
                        URL.revokeObjectURL(src);//释放内存
                        let width=this.width;
                        let height=this.height;
                        let imgCanvas=document.createElement('canvas');//创建canvas元素
                        imgCanvas.width=width;
                        imgCanvas.height=height;
                        let ctx=imgCanvas.getContext('2d');//2d渲染
                        ctx.drawImage(this,0,0,width,height);//将图片以原有尺寸绘制到canvas中
                        //转换成base64并压缩，可以从0到1的区间内选择图片的质量。如果超出取值范围，将会使用默认值0.92。其他参数会被忽略
                        if(imgType==='image/webp'||imgType==='image/jpeg'){
                            imgCanvas.toBlob(function (b) {
                                let compressUrl=URL.createObjectURL(b);//压缩后的文件url
                                let fileName=imgFile.name.substring(0,imgFile.name.lastIndexOf('.'))+'.'+imgType.split('/')[1];//重构文件名
                                let file=_that.blobToFile(b,fileName,imgType);//Blob实例转成File实例
                                resolve({
                                    url:compressUrl,
                                    file:file,
                                });
                                imgCanvas=null;//释放内存
                            },imgType,quality);
                        } else {
                            imgCanvas.toBlob(function (b) {
                                let compressUrl=URL.createObjectURL(b);
                                let fileName=imgFile.name.substring(0,imgFile.name.lastIndexOf('.'))+'.'+imgType.split('/')[1];
                                let file=_that.blobToFile(b,fileName,imgType);
                                resolve({
                                    url:compressUrl,
                                    file:file,
                                },imgType);
                                imgCanvas=null;//释放内存
                            },imgType);
                        }
                    }catch (e) {
                        reject(e);
                    }
                }
            })
        },
        //blob转file
        blobToFile(blob,fileName,type) {
            return new window.File([blob], fileName, {type: type});
        },
         // 粘贴
        pasteFn(e){
            if (e.clipboardData || e.originalEvent) {
                //某些chrome版本使用的是event.originalEvent
                let clipboardData = (e.clipboardData || e.originalEvent.clipboardData);
                let plain=''
                    if(clipboardData.items){
                    // for chrome
                    var  items = clipboardData.items,
                        len = items.length,
                        blob = null;
                    for (var i = 0; i < len; i++) {
                        if (items[i].type.indexOf("image") !== -1) {
                            //getAsFile() 此方法只是living standard firefox ie11 并不支持
                            blob = items[i].getAsFile();
                        }
                            if (items[i].type.indexOf('plain')!==-1){
                                plain='plain'
                            }
                    }
                    if(blob !== null){
                        //blob对象显示
                        let reader = new FileReader();
                        let url = ""
                        //base64码显示
                        reader.onload = function (event) {
                            console.log(event,'event');
                            url = event.target.result;
                        }
                        reader.readAsDataURL(blob);
                        setTimeout(()=>{
                            this.isPaste = true;
                            console.log(e,'粘贴');
                            this.dialogVisible = true;
                            this.media_inLoading = true;
                            this.uploadFile(url || '','paste');
                        },600)
                    } else if (plain !== 'plain'){
                        this.$message.error("暂不支持粘贴文件类型");
                    }
                }
            }
        },
        async dataURLtoFile(dataurl) {
            // 获取到base64编码
            let base64_str = dataurl.split(',');
            // 将base64编码转为字符串
            let decode_str = atob(base64_str[1]);
            let n = decode_str .length;
            // 创建初始化为0的，包含length个元素的无符号整型数组
            let u8arr = new Uint8Array(n) ;
            while (n--) {
                u8arr[n] = decode_str .charCodeAt(n);
            }
            return new File([u8arr], uuidv4().toString().replaceAll("-", "")+'.jpeg', {
                type: 'image/jpeg',
            });
        },
        async uploadFile(base64String,type){
            if(base64String !== ''){
                let fileType = this.getExtensionFromBase64(base64String);
                let allowType = ['jpg', 'JPG', 'jpeg', 'JPEG', 'png', 'PNG'];
                if(allowType.indexOf(fileType) === -1){
                    this.$message.warning(`您好，暂不支持上传 ${fileType} 类型文件！`,2000);
                    return false;
                }
                let file = await this.dataURLtoFile(base64String,);
                this.handlerUploadImage('paste',file);
            }
        },
        // 根据base64 , 获取文件后缀名
        getExtensionFromBase64(base64) {
            let re = new RegExp('data:image/(?<ext>.*?);base64,.*')
            let res = re.exec(base64)
            if (res) {
                return res.groups.ext
            }
        },
        async handlerUploadImage(type,file){
            let newFile = file;
            if(!(file.size <= 10*1024*1024)){
                this.$message.warning("请上传小于10M的图片!");
            } else {
                if(type === 'upload'){
                   this.chatList.push({
                        type:"IMAGE",
                        isLoading:true,
                        user:'user'
                    })    
                }
                // 大于4M的图片需要压缩以后再上传
                if(4*1024*1024 < file.size){
                    console.log('大于4M的图片需要压缩以后再上传');
                    await this.imgCompress(file,'image/jpeg',0.6).then(res => {
                        newFile = res.file;
                        newFile.uid = file.uid;
                        console.log(newFile,'压缩后的文件对象');//压缩后的文件对象
                    }).catch(e=>{
                        console.log(e);
                    })
                } 
                console.log(newFile,'newFile');
                let res = multipartUpload(
                    ossConfig,
                    newFile,
                    null,
                    null
                );
                res.then((res) => {
                    console.log("upload result:", res);
                    if(type === 'paste'){
                        this.pasteImgaeUrl = ossFileUrl(ossConfig, res.name);
                        this.media_inLoading = false;
                    } else {
                        let content = {
                            urls: []
                        }
                        content.urls.push(ossFileUrl(ossConfig, res.name));
                        this.sendTestContent('IMAGE',content,null,true);
                        this.showUploadImg = false;
                        this.$refs.input.focus();
                    }
                    
                }).catch((err) => {
                    console.log("upload err", err);
                    this.$message.error("图片上传失败，请稍后再试");
                });
            }
        },
        // 取消发送
        cancelSend(){
            this.dialogVisible = false;
        },
        // 确认发送
        confirmSend(){
            let content = {
                urls:[this.pasteImgaeUrl]
            }
            this.sendTestContent('IMAGE',content,null,true)
            this.dialogVisible = false;
            this.$refs.input.focus();
        },
        // 预览图片
        viewImage(item){
            this.currentImage = {
                imageUrl:item.content.urls[0],
                sortNum:0
            }
            this.previewList = item.content.urls
            this.showImageViewer = true;
            this.stopOtherAudio("");
        },
        // 聚焦
        focusEvent(){
            if (this.testValue.length > 0) {
                this.canSendMessage = true;
                this.showUploadImg = false;
            } else {
                this.canSendMessage = false;
                this.showUploadImg = false;
            }
        },
        showImageContent(){
            this.showUploadImg = !this.showUploadImg;
        },
        inputEvent() {
            if (this.testValue.length > 0) {
                this.canSendMessage = true;
                this.showUploadImg = false;
            } else {
                this.canSendMessage = false;
                this.showUploadImg = false;
            }
        },
        beforeUpload: function (file) {
            console.log("before upload:", file);
            let fileType = file.type;
            if (!fileType.startsWith("image/")) {
                this.$message.error("请上传图片");
                return false;
            }
            this.media_inLoading = true;
        },
        aliyunOssRequest(data) {
            let file = data.file;
            this.handlerUploadImage('upload',file)
        },
        // 暂停当前播放外的其他音频组件
        stopOtherAudio(refId){
            console.log(refId,this.$refs.audioTagCell);
            this.$nextTick(() => {
                this.$refs.audioTagCell && this.$refs.audioTagCell.forEach(item => {
                    item.stopAudioPlay(refId);
                })
            })
        },
        clickSearch(item){
            item.isShowDiscriminate = true;
            item.isShowSelect = false;
        },
        hideDiscriminate(item){
            item.isShowDiscriminate = false;
            item.isShowSelect = true;
        },
        // 意图定位
        goIntentLocation(item){
            console.log(item);
            if(item.testWindowDto.skillName === '特殊规则'){
                console.log('其他知识库限制,不能跳转');
            } else if(!item.testWindowDto.userCanSeeSkill){
                this.$message.warning('暂无意图访问权限')
            } else {
                sessionStorage.setItem("_activeSkill", item.testWindowDto.skillId);
                sessionStorage.setItem("_activeClass", item.testWindowDto.intentTypeId);
                sessionStorage.setItem("openIntentWeb", 1);
                sessionStorage.setItem("openIntentId", item.testWindowDto.intentId);
                this.$router.push({
                    name: 'botIntent',
                    query:{
                        id: this.currentBot.id,
                        name:this.currentBot.name,
                        refApiKey:item.testWindowDto.botRefApiKey,
                        from:'cursor',
                        tabIndex:"skill" 
                    }
                })
                this.$eventBus.$emit("bot-heathly-visible", {
                    flag:false,
                    type:'goLocationIntent',
                })
            }
        },
        //获取ref下拉值
        getTemplateList(param, id) {
            let url = "/bearing-api/data-template/data/page";
            console.debug("ref param", param);
            param.dataQuery[param.field.fieldId].templateId = param.templateId;
           this.FetchPost(url, param.dataQuery[param.field.fieldId])
                .then((res) => {
                    console.debug("ref res", res);
                    if (res.code === "0") {
                        let list = res.data.data;
                        let templateList = [];
                        templateList = list.map((res) => {
                            let info = {};
                            for (let key in res.fieldValues) {
                                if (Array.isArray(param.fieldId)) {
                                    let vals = "";
                                    let values = "";
                                    param.fieldId.forEach((val) => {
                                        vals += val;
                                        if (res.fieldValues[val]) {
                                            values += res.fieldValues[val];
                                        }
                                    });
                                    info[vals] = values;
                                    info.id = res.id;
                                    info.name = values;
                                } else {
                                    if (param.fieldId === key) {
                                        info[key] = res.fieldValues[key];
                                        info.id = res.id;
                                        info.name = res.fieldValues[key];
                                    }
                                }
                            }
                            return info;
                        });
                        this.$nextTick(() => {
                            let ref = "formTemplate" + id;
                            console.debug(
                                "refTemplateList",
                                this.$refs[ref],
                                this.$refs[ref][0].refTemplateList
                            );
                            if (this.$refs[ref]) {
                                this.$set(
                                    this.$refs[ref][0].refTemplateList,
                                    param.field.fieldId,
                                    [
                                        ...this.$refs[ref][0].refTemplateList[
                                            param.field.fieldId
                                        ],
                                        ...templateList,
                                    ]
                                );
                            }
                            if (!param.refNextPage[param.field.fieldId]) {
                                this.$set(
                                    param.refNextPage[param.field.fieldId],
                                    "hasNextPage",
                                    false
                                );
                            }
                            if (param.refNextPage[param.field.fieldId]) {
                                param.refNextPage[
                                    param.field.fieldId
                                ].hasNextPage = res.data.hasNextPage;
                            }
                        });
                    }
                });
        },
        sendAiGuide(recommendType, cell) {
            console.log(recommendType, cell);
            if (recommendType === 0) {
                this.sendTestContent(
                    "INTELLIGENT",
                    JSON.stringify({
                        recommendType: recommendType,
                        name: cell.questionName,
                        id: cell.intentId,
                    }),
                    cell.questionName,
                    true
                );
            } else {
                this.sendTestContent(
                    "INTELLIGENT",
                    JSON.stringify({
                        recommendType: recommendType,
                        name: cell.exampleQuestion,
                        id: cell.intentId,
                    }),
                    cell.exampleQuestion,
                    true
                );
            }
        },
        emptyClick() {},
        showIntentSelect() {
            console.log(111111);
            this.showArrow = false;
            this.searchIntentName = "";
            this.searchAllSkillIntentList = [];
        },
        hideIntentSelect() {
            this.showArrow = true;
            this.searchIntentName = "";
            this.searchAllSkillIntentList = [];
        },
        searchAllSkillFocus() {
            if (
                this.searchIntentName !== "" &&
                !this.showSearchAllSkillIntent
            ) {
                this.showSearchAllSkillIntent = true;
                this.showSearchAllSkillIntentNoData = false;
            }
        },
        // 获取搜索意图列表
        searchAllSkillIntent() {
            if (this.searchIntentName === "") {
                this.searchAllSkillIntentList = [];
                this.showSearchAllSkillIntentNoData = true;
            } else {
                this.FetchGet(
                    this.requestUrl.intent.searchAllSkillIntent +
                        "?apiKey=" +
                        this.currentBot.apiKey +
                        "&keyWord=" +
                        this.searchIntentName +
                        "&needOther=false"
                )
                    .then((res) => {
                        if (res.code === "0") {
                            this.searchAllSkillIntentList = [
                                ...[],
                                ...(res.data || []),
                            ];
                            if (this.searchAllSkillIntentList.length > 0) {
                                this.showSearchAllSkillIntent = true;
                                this.showSearchAllSkillIntentNoData = false;
                            } else {
                                this.showSearchAllSkillIntentNoData = true;
                            }
                            this.isSearch = true;
                        } else {
                            this.$message.error(res.message);
                        }
                    })
                    .catch((err) => {
                        this.$message(err);
                    });
            }
        },
        onRadioClick(value, showValue, answerApiKey, redioType) {
            this.sendTestContent("RADIO", value, showValue, false);
            // this.$emit(
            //     "onRadioClick",
            //     value,
            //     showValue,
            //     answerApiKey,
            //     redioType
            // );
        },
        getIntentAllLocal(val) {
            let id = val;
            if (Array.isArray(id)) {
                id = id[id.length - 1];
            }
            let localStr = "";
            this.intentionList.forEach((skill) => {
                if (skill.children) {
                    skill.children.forEach((iClass) => {
                        if (iClass.children) {
                            iClass.children.forEach((intent) => {
                                if (intent.id == id) {
                                    // localStr = `${skill.name} / ${iClass.name} / ${intent.name}`;
                                    localStr = `${intent.name}`;
                                }
                            });
                        }
                    });
                }
            });
            return localStr;
        },
        positiveFeedback(data) {
            // data.feedback = data.positiveFeedback!=null?data.positiveFeedback:data.negativeFeedback;
            let res = {};
            res.feedbackActions = data.feedbackActions;
            res.feedbackUid = data.feedbackUid;
            res.feedback =
                data.feedback.positiveFeedback != null
                    ? data.feedback.positiveFeedback
                    : data.feedback.negativeFeedback;
            this.sendTestContent("FEEDBACK", JSON.stringify(res), null, false);
        },
        showIframe(src) {
            this.src = src;
            this.iframeVisible = true;
            // const cookie = document.cookie
            // this.iframeWin.postMessage(cookie, this.src)
        },
        closeIframe() {
            this.src = "";
            this.iframeVisible = false;
        },
        confirmValue(data) {
            this.sendTestContent(
                "VALUE_CONFIRM",
                JSON.stringify(data),
                null,
                false
            );
        },
        formCommit(data) {
            this.sendTestContent(
                "FORM_SUBMIT",
                JSON.stringify(data),
                null,
                false
            );
        },
        templateInfo(data) {
            this.sendTestContent(
                "TEMPLATE_CARD_BUTTON",
                data,
                data.name,
                false
            );
        },
        askCkeditorBtnClick(_this) {
            let jsonVar = JSON.stringify({
                gotoContent: {
                    scope:
                        _this.getAttribute("__event") === "TO_NODE"
                            ? "NODE"
                            : "INTENT",
                    value: _this.getAttribute("__value"),
                },
                assignmentsJson: _this.getAttribute("__assignments"),
            });
            this.sendTestContent("RICH_TEXT_BUTTON", jsonVar, null, false);
        },
        previewImage(src) {
            let type = typeof src;
            let previewImageUrl =
                type === "object" && src.getAttribute("src")
                    ? src.getAttribute("src")
                    : src;
            this.showPreview = true;
            this.previewImageUrl = previewImageUrl;
            this.stopOtherAudio("");
           
        },
        satisfacte(chooseValue, answer, unSatisfactionChoose) {
            // 发送消息记录
            this.sendTestContent(
                "REPLAY_4_SATISFACTION",
                chooseValue + "_" + answer,
                chooseValue,
                false
            );
            // 不满意选择
            if (chooseValue == "不满意") {
                let replay4unSatisfactionChoose = {
                    type: "unSatisfactionChoose",
                    content: {
                        description: answer,
                        options: unSatisfactionChoose,
                    },
                };
                this.addAnswerList([replay4unSatisfactionChoose]);
            } else {
                this.addAnswerList([
                    { type: "answer_text", content: { list: [answer] } },
                ]);
            }
        },

        changeCurrentBot(id) {
            this.cleanSession();
            for (let i = this.botList.length - 1; i >= 0; i--) {
                if (id == this.botList[i].id) {
                    this.currentBot.name = this.botList[i].name;
                    this.currentBot.apiKey = this.botList[i].apiKey;
                    this.currentBot.userId = this.botList[i].userId;
                    this.currentBot.botProfilePhoto =
                        this.botList[i].botProfilePhoto;
                    this.refreshIntentList();
                    break;
                }
            }
        },
        cleanSession(sendConnectInfo) {
            // this.sessionId = new Date().getTime();
            this.chatList = [];
            this.isPaste = false;
            if (sendConnectInfo) {
                this.sendStartSesseion();
            }
        },
        handleClose() {
            this.testDrawerVisible = false;
            console.log(1331);
            this.stopOtherAudio("");
            sessionStorage.setItem("isOpenTestDrawer", false);
        },
        changeIntent(item) {
            console.log('changeIntent',1405);
            let intentionId = item.intentId[item.intentId.length - 1];
            this.FetchPost(
                this.requestUrl.intentQuestion.changeQuestionIntent,
                {
                    intentId: intentionId,
                    question: item.content,
                }
            ).then((res) => {});
            //显示训练模型Box，5S后关闭
            this.isShow = true;
            let _this = this;
            setTimeout(function () {
                _this.isShow = false;
            }, 5000);
        },

        switchSkillClass(data, content, index) {
            let intentionId = data.id;
            this.FetchPost(
                this.requestUrl.intentQuestion.changeQuestionIntent,
                {
                    intentId: intentionId,
                    question: content,
                }
            ).then((res) => {});
            // this.$refs.intentSelectTree.setCheckedKeys([intentionId]);
            //显示训练模型Box，5S后关闭
            this.chatList[index].intentId = intentionId;
            this.isShow = true;
            this.searchAllSkillIntentList = [];
            this.searchIntentName = "";
            this.$refs.intentRefName.click();
            let _this = this;
            setTimeout(function () {
                _this.isShow = false;
            }, 5000);
        },

        changeIntentNew(data, node, cell, item, index) {
            console.log('changeIntentNew',data, node, cell, item, index);
            let intentionId = data.id;
            let url = '',params = {}
            if(item.type === 'IMAGE'){
                url = this.requestUrl.intentQuestion.imageChange
                params = {
                    intentId: intentionId,
                    urls: item.content.urls,
                }
            } else {
                url = this.requestUrl.intentQuestion.changeQuestionIntent
                params = {
                    intentId: intentionId,
                    question: item.content,
                }
            }
            if (intentionId.indexOf("S") === -1 && intentionId.indexOf("T") === -1) {
                this.FetchPost(url,params).then((res) => {});
                // this.$refs.intentSelectTree.setCheckedKeys([intentionId]);
                //显示训练模型Box，5S后关闭
                this.chatList[index].intentId = intentionId;
                this.isShow = true;
                let _this = this;
                this.$refs.intentRefName.click();
                setTimeout(function () {
                    _this.isShow = false;
                }, 5000);
            }
        },

        addAnswerList(contents) {
            this.fastSelectList = [];
            for (let i = 0; i < contents.length; i++) {
                contents[i].user = "bot";
                let type = contents[i].type;
                if (
                    type == "answer_text" ||
                    type == "answer_welcometext" ||
                    type == "answer_correct"
                ) {
                    let textList = contents[i].content.list;
                    if (textList && textList.length != 0) {
                        let length = textList.length;
                        let random = randomNum(length);
                        let text = {
                            type: "answer_text",
                            text: textList[random].replace("/\n/g", "<br/>"),
                            user: "bot",
                            actionId: contents[i].id,
                        };
                        if (
                            type == "answer_text" &&
                            contents[i].content.recommend != undefined &&
                            contents[i].content.recommend.status
                        ) {
                            text.recommend = contents[i].content.recommend;
                            text.showRecommend = true;
                        }
                        this.chatList.push(text);
                    }
                } else if (type == "answer_rich_text") {
                    console.log('answer_rich_text');
                    //富文本内图片自适应高度宽度
                    // const regex = new RegExp("<img", "gi");
                    // contents[i].content.html = contents[i].content.html.replace(
                    //   regex,
                    //   `<img style="max-width: 100%; height: auto"`
                    // );
                    let text = {
                        type: "answer_text",
                        text: contents[i].content.html,
                        user: "bot",
                        actionId: contents[i].id,
                    };
                    if (
                        contents[i].content.recommend != undefined &&
                        contents[i].content.recommend.status
                    ) {
                        text.recommend = contents[i].content.recommend;
                        text.showRecommend = true;
                    }
                    this.chatList.push(text);
                } else if (type == "answer_rich_text_pro") {
                    let text = {
                        type: "answer_text",
                        text: contents[i].content.html,
                        user: "bot",
                        actionId: contents[i].id,
                    };
                    if (
                        contents[i].content.recommend != undefined &&
                        contents[i].content.recommend.status
                    ) {
                        text.recommend = contents[i].content.recommend;
                        text.showRecommend = true;
                    }
                    this.chatList.push(text);
                } else if (type == "answer_image") {
                    let image = {
                        type: "answer_image",
                        url: contents[i].content.url,
                        user: "bot",
                    };
                    if (
                        contents[i].content.recommend != undefined &&
                        contents[i].content.recommend.status
                    ) {
                        image.recommend = contents[i].content.recommend;
                        image.showRecommend = true;
                    }
                    this.chatList.push(image);
                } else if (type == "answer_video") {
                    if (
                        contents[i].content.recommend != undefined &&
                        contents[i].content.recommend.status
                    ) {
                        contents[i].recommend = contents[i].content.recommend;
                        contents[i].showRecommend = true;
                    }
                    this.chatList.push(contents[i]);
                } else if (type == "answer_radio") {
                    // let radio = {
                    //     type: "answer_radio",
                    //     description: contents[i].content.description,
                    //     user: "bot",
                    //     options: contents[i].content.options,
                    //     actionId: contents[i].id,
                    // };
                    let radio = {
                        type: "answer_radio",
                        content: {
                            description: contents[i].content.description,
                            options: contents[i].content.options,
                            actionId: contents[i].id,
                            groupNumber: contents[i].content.groupNumber,
                            groupVisible: contents[i].content.groupVisible
                        },
                        id: contents[i].id,
                        user: "bot",
                    };
                    this.chatList.push(radio);
                } else if (type == "action_send_message_to_bot") {
                    let radio = {
                        type: "action_send_message_to_bot",
                        description: contents[i].content.description,
                        user: "bot",
                        options: contents[i].content.options,
                        nodeId: contents[i].content.nodeId,
                    };
                    this.chatList.push(radio);
                } else if (type == "action_question") {
                    let question = {
                        type: "answer_text",
                        text: contents[i].content.questions[0],
                        user: "bot",
                    };
                    this.chatList.push(question);
                } else if (type == "action_transfer4pluto") {
                    let content = contents[i].content;
                    let transfer = {
                        type: "answer_text",
                        text:
                            (content.mode != "select"
                                ? content.text + "<br/>"
                                : "") +
                            "[mode:" +
                            content.mode +
                            "][serverId:" +
                            content.customServerId +
                            "]",
                        user: "bot",
                    };
                    this.chatList.push(transfer);
                } else if (type == "action_fast_select") {
                    this.fastSelectList = contents[i].content.list;
                } else {
                    this.chatList.push(contents[i]);
                }
            }
            
            this.$nextTick(() => {
                setTimeout(() => {
                    this.$refs.grContent.scrollTo(0, 10000000);
                }, 500);
            });
        },
        refreshIntentList() {
            if (this.currentBot.id == null) {
                return;
            }
            this.FetchGet(
                this.requestUrl.intent.getIntentTree +
                    "?bId=" +
                    this.currentBot.id +
                    "&needOther=true"
            ).then((res) => {
                if (res.code === "0") {
                    this.intentionList = res.data;
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        sendTestContent(type, value, showValue, needShowIntent, other) {
            
            console.log(type, value, showValue, needShowIntent, other);
            if (type != 'TEMPLATE_CARD_BUTTON' && type !== 'IMAGE') {
                if (value.indexOf('recdNodeId') != -1 && showValue == '') {
                    this.$message.error("请配置关联意图推荐文案");
                    return false;
                }
            }
            
            if (
                type == "FAST_SELECT" &&
                JSON.parse(value).action.scope == "LINK"
            ) {
                window.open(JSON.parse(value).action.value);
                return false;
            }
            let questionId = new Date().getTime();
            this.testValue = "";

            if (value != null && value !== "") {
                 if (showValue != null) {
                    this.chatList.push({
                        questionId: questionId,
                        requestCompleted: false,
                        intentId: null,
                        needShowIntent: needShowIntent,
                        content:showValue,
                        user: "user",
                        type:"TEXT",
                        isShowDiscriminate: false,
                        isShowSelect:true,
                        showIntentMark:false,
                        isLoading:false,
                        testWindowDto: {
                            skillName: "",
                            intentTypeName: "",
                            recognizeEngines: "",
                            entity: [],
                            intentId:"",
                            intentName:'',
                            intentTypeId:'',
                            intentTypeName:"",
                            onLine:true,
                            upToStandard:true
                        },
                    });
                } else {
                    if(type === 'IMAGE'){
                        let chatObj = {
                            questionId: questionId,
                            requestCompleted: false,
                            intentId: null,
                            needShowIntent: needShowIntent,
                            content: value,
                            user: "user",
                            type:"IMAGE",
                            isShowDiscriminate: false,
                            isShowSelect:true,
                            showIntentMark:false,
                            isLoading:false,
                            testWindowDto: {
                                skillName: "",
                                intentTypeName: "",
                                recognizeEngines: "",
                                entity: [],
                                intentId:"",
                                intentName:'',
                                intentTypeId:'',
                                intentTypeName:"",
                                onLine:true,
                                upToStandard:true
                            }
                        }
                        if(this.isPaste){
                            this.chatList.push(chatObj)
                        } else {
                            this.chatList.splice(this.chatList.length-1,1,chatObj)
                        }
                        this.isPaste = false;
                    }
                }
                console.log(this.chatList,'chatList');
                let param = {
                    botId: this.currentBot.id,
                    questionId: questionId,
                    sessionId: this.sessionId,
                    question:
                        type == "action_send_message_to_bot"
                            ? JSON.stringify({
                                  content: value,
                                  nodeId: other,
                              })
                            : value,
                    inputType:
                        type == "action_send_message_to_bot"
                            ? "WEBHOOK_RADIO"
                            : type,
                };

                if (type == "action_send_message_to_bot") {
                    param.question = JSON.stringify({
                        content: value,
                        nodeId: other,
                    });
                    param.inputType = "WEBHOOK_RADIO";
                } else if (type == "TEMPLATE_CARD_BUTTON") {
                    param.question = JSON.stringify(value);
                    if (value.scope == "LINK") {
                        let dom = document.createElement("div");
                        dom.innerHTML = value.value;
                        var tempwindow = window.open("_blank");
                        tempwindow.location.href = dom.innerText;
                    }
                } else if (type == "IMAGE"){
                    param.question = JSON.stringify(value);
                    param.inputType = type;
                } else {
                    param.question = value;
                    param.inputType = type;
                }

                // this.FetchPost(this.requestUrl.dialog.test_v3, param).then((res) => {
                let url = this.requestUrl.dialog.test_v3;
                let pro = this.FetchPost(url, param);
                pro.then((res) => {
                    if (res.code === "0") {
                        this.refreshIntentList();
                        let data = res.data;
                        let answer = data.processAction;
                        let intentId = null;
                        if (data.intentId !== -9999) {
                            intentId = data.intentId;
                        }

                        let questionId = data.questionId;

                        for (let i = this.chatList.length - 1; i >= 0; i--) {
                            let chat = this.chatList[i];
                            if (
                                chat.questionId != null &&
                                chat.questionId == questionId
                            ) {
                                chat.requestCompleted = true;
                                chat.testWindowDto = data.testWindowDto;
                                for (
                                    let j = this.intentionList.length - 1;
                                    j >= 0;
                                    j--
                                ) {
                                    for (
                                        let k =
                                            this.intentionList[j].children
                                                .length - 1;
                                        k >= 0;
                                        k--
                                    ) {
                                        for (
                                            let l =
                                                this.intentionList[j].children[
                                                    k
                                                ].children.length - 1;
                                            l >= 0;
                                            l--
                                        ) {
                                            if (
                                                this.intentionList[j].children[
                                                    k
                                                ].children[l].id == intentId
                                            ) {
                                                chat.intentId = [
                                                    this.intentionList[j].id,
                                                    this.intentionList[j]
                                                        .children[k].id,
                                                    this.intentionList[j]
                                                        .children[k].children[l]
                                                        .id,
                                                ];
                                            }
                                        }
                                    }
                                }
                                break;
                            }
                        }
                        if (answer != null) {
                            this.addAnswerList(answer);
                        } else {
                            this.$nextTick(() => {
                                setTimeout(() => {
                                    this.$refs.grContent.scrollTo(0, 10000000);
                                }, 500);
                            });
                        }
                        this.canSendMessage = false;
                        this.showUploadImg = false;
                    } else {
                        this.$message.error(res.message);
                    }
                    // for (let i = 0; i < this.chatList.length; i++) {
                    //     if (this.chatList[i].type === "action_form")
                    //         console.log(this.chatList[i].content);
                    // }
                });
            }
        },
        sendStartSesseion() {
            this.sessionId = new Date().getTime();
            let initEntities = {
                params: [
                    {
                        name: "SYS_机器人标识",
                        value: this.currentBot.apiKey,
                        expired: -1,
                    },
                    {
                        name: "SYS_用户标识",
                        value: this.currentBot.userId,
                        expired: -1,
                    },
                    { name: "SYS_消息渠道", value: "测试窗", expired: -1 },
                ],
            };
            this.sendTestContent(
                "START_SESSION",
                JSON.stringify(initEntities),
                null,
                false
            );
        },
    },
    updated() {
        this.$emit("changeTestContentVisible", this.testDrawerVisible);
    },
    computed: {
        videoOptions() {
            return function (videoContent) {
                return {
                    muted: true,
                    language: "en",
                    playbackRates: [0.7, 1.0, 1.5, 2.0],
                    sources: [
                        {
                            type: "video/mp4",
                            src: videoContent.url,
                        },
                    ],
                    width: "260",
                    height: "200",
                };
            };
        },
    },
    watch: {
        "currentBot.id": function () {
            this.sendStartSesseion();
        },
        testDrawerVisibleP: function (n) {
            this.canSendMessage = false;
            this.showUploadImg = false;
            this.testDrawerVisible = this.testDrawerVisibleP;
            if (this.testDrawerVisible === true) {
                this.isPaste = false;
                this.CompanyIsOld = sessionStorage.getItem('CompanyIsOld') == 'true' ? true : false;
                this.FetchGet(this.requestUrl.bot.getBotList, {
                    page: 1,
                    size: 100,
                }).then((res) => {
                    if (res.code === "0") {
                        this.botList = res.data.list;
                        let botMap = {};
                        for (let i = 0; i < this.botList.length; i++) {
                            let bot = this.botList[i];
                            botMap[bot.id] = bot;
                        }
                        if (this.$route.query.bid) {
                            this.currentBot.id = this.$route.query.bid;
                            this.currentBot.name = this.$route.query.bName;
                            if (botMap[this.currentBot.id]) {
                                this.currentBot.apiKey =
                                    botMap[this.currentBot.id].apiKey;
                                this.currentBot.userId =
                                    botMap[this.currentBot.id].userId;
                                this.currentBot.botProfilePhoto =
                                    botMap[this.currentBot.id].botProfilePhoto;
                            }
                        } else if (this.$route.query.id) {
                            this.currentBot.id = this.$route.query.id;
                            this.currentBot.name = this.$route.query.name;
                            if (botMap[this.currentBot.id]) {
                                this.currentBot.apiKey =
                                    botMap[this.currentBot.id].apiKey;
                                this.currentBot.userId =
                                    botMap[this.currentBot.id].userId;
                                this.currentBot.botProfilePhoto =
                                    botMap[this.currentBot.id].botProfilePhoto;
                            }
                        } else {
                            this.currentBot.id = this.botList[0].id;
                            this.currentBot.name = this.botList[0].name;
                            this.currentBot.apiKey = this.botList[0].apiKey;
                            this.currentBot.userId = this.botList[0].userId;
                            this.currentBot.botProfilePhoto =
                                this.botList[0].botProfilePhoto;
                        }
                        this.refreshIntentList();
                    } else {
                        this.$message.error(res.message);
                    }
                });
            }
        },
        // chatList:{
        //     handler(n){
        //         console.log(n,'mmm');
        //     },
        //     deep:true
        // }
    },
};
</script>
<style lang="less">
.el-popover.gr-test-intent-point-pop {
    padding: 0 !important;
    margin-top: 0 !important;
    border: none;
    background: transparent;
}
.el-popover.gr-test-intent-point-pop,.el-popover.popover-box{
    z-index: 2222;
}
#gr-test {
    height: 100vh;
    .singleItem{
        max-width: 300px !important;
    }
    .r-loading-box {
        height: 24px;
        padding: 5px 0px;
        .media-body-loadings {
            background-color: rgba(0, 0, 0, 0) !important;
        }
    }
    .paste-imgae-popup{
        .defaultDialog{
            margin-top: 25vh !important;
        }
        #popup-assembly{
            width: 350px !important;
            position: fixed !important;
            margin:0 !important;
            right: 35px;
        }
    
    }

    .new-version-rich {
        img {
            display: block;
        }
    }
    .answer-text-content{
        img{
            max-width: 230px;
            border-radius: 25px;
        }
        
        ol {
            list-style-type: roman;
        }
        ul {
            list-style-type: square;
        }
        h2 {
            font-size: 25px;
        }

        h3 {
            font-size: 21px;
        }

        h4 {
            font-size: 18px;
        }
        a{
            font-weight: bolder;
        }

    }
}

</style>
<style lang="less" scoped>
@import "./../../assets/less/test/test.less";
.expand-enter-active {
    transition: all 1s ease;
    max-height: 500px;
    overflow: hidden;
}

.expand-leave-active {
    transition: all 1s ease;
    max-height: 0px;
    overflow: hidden;
}

.expand-enter,
.expand-leave {
    max-height: 0;
}
.closeIframe {
    position: fixed;
    top: 0px;
    left: 0;
    width: 100%;
    height: 40px;
    background-color: white;
    text-align: right;
    span {
        font-size: 16px;
        display: inline-block;
        height: 40px;
        line-height: 40px;
        margin-right: 20px;
        cursor: pointer;
    }
}
#template-iframe {
    position: fixed;
    top: 40px;
    left: 0;
    width: 100%;
    height: calc(100% - 40px);
}
.action_feedback {
    // background-color: #323232 !important;
}
/deep/ figure {
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}

/deep/ .vjs-control-bar {
    margin-left: -10px;
    background-color: rgba(0, 0, 0, 0);
}
#gr-test {
    /deep/ .pcFormClass {
        width: 300px;
    }
}
</style>


